import devConfig from './firebase-config-dev';

export type FirebaseConfig = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
};

const FIREBASE_NODE = 'us-central1';

export const fetchConfig = async (): Promise<FirebaseConfig> => {
  // Dynamically load firebase config
  // from: https://firebase.google.com/docs/hosting/reserved-urls
  return fetch('/__/firebase/init.json').then((response) => {
    if (response.ok) {
      return response
        .json()
        .then((data) => data)
        .catch((err) => {
          console.log(err);
          // Will fail if run locally because of the body format
          // Use default config
          return devConfig;
        });
    }
  });
};

export const getFunctionsBaseUrl = (projectId: string): string => {
  return process.env.REACT_APP_FUNCTIONS_EMULATOR
    ? `http://localhost:5001/${projectId}/${FIREBASE_NODE}`
    : `https://${FIREBASE_NODE}-${projectId}.cloudfunctions.net`;
};
