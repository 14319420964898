/* eslint-disable no-restricted-syntax */
import * as React from 'react';

import {
  BannerButton,
  IndexBlackSectionTitle,
  Page,
  Section,
  flexgutter,
} from '../components/common';
import {
  ContactFormActions,
  ContactFormField,
  ContactFormInput,
  ContactFormSubmitButton,
  ContactFormSubmitError,
  phoneRegex,
} from '../components/forms';
import { breakpointsList, device, media } from '../styles/style-utils';
import { fetchConfig, getFunctionsBaseUrl } from '../firebase';

import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import SEO from '../components/seo';
import axios from 'axios';
import styled from '@emotion/styled';

type Props = {
  path: string;
};

const ReferralsBlock = styled.div`
  ${flexgutter};
  p {
    line-height: 24px;
    display: flex;
    flex-basis: 100%;
  }
`;

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const WhatIsSilofit = styled.div`
  padding-bottom: 128px;
`;
const ReferralsPage: React.FC = ({ path } = props) => {
  const [invitedBy, setInvitedBy] = React.useState<string | null>();
  const [phone, setPhone] = React.useState<string | undefined>();
  const [status, setStatus] = React.useState<
    'success' | 'failed' | 'sending' | undefined
  >();
  const [buttonLabel, setButtonLabel] = React.useState<
    'Send Link' | 'Success' | 'Invalid' | 'Sending...'
  >('Send Link');
  const [formError, setFormError] = React.useState<string | undefined>();

  React.useEffect(() => {
    const invitedBy = getParameterByName('invitedby', document.location.href);
    setInvitedBy(invitedBy);
    if (!invitedBy) {
      // eslint-disable-next-line no-alert
      alert(
        'Invalid referral link, please contact support from the footer link.'
      );

      setButtonLabel('Invalid');
      setFormError(
        'Invalid referral link, please contact support from the footer'
      );
    }
  }, [invitedBy]);

  const handleChange = (event) => {
    setPhone(event.target.value);
    // In any case, reset the form status
    setStatus(undefined);
    setFormError(undefined);
  };
  const updateButtonLabel = () => {
    switch (status) {
      case 'sending':
        setButtonLabel('Sending...');
        break;
      case 'success':
        setButtonLabel('Success');
        break;
      case 'failed':
        setButtonLabel('Invalid');
        break;
      default:
        setButtonLabel('Send Link');
        break;
    }
  };

  React.useEffect(() => {
    updateButtonLabel();
  }, [status]);

  const handleSubmit = () => {
    // Safety check: no need to resubmit if all good or nothing was changed
    if (status) {
      return;
    }

    setStatus('sending');

    // Quick form validation
    // All fields are mandatory
    for (const value of [phone]) {
      if (!value) {
        setStatus('failed');
        setFormError('All fields are required');
        return;
      }
    }

    // Phone format
    if (!phone?.match(phoneRegex)) {
      setStatus('failed');
      setFormError('Your phone number does not seem to be valid');
      return;
    }

    const data: any = {
      invitedBy,
      phone_number: phone,
    };
    fetchConfig()
      .then((config) => {
        return axios.post(
          `${getFunctionsBaseUrl(config.projectId)}/sendViralityLinkToPhone`,
          data
        );
      })
      .then((response) => {
        setStatus(response.data.success ? 'success' : 'failed');
      })
      .catch((error) => {
        console.error(error);
        setStatus('failed');
      });
  };
  return (
    <Layout path={path}>
      <SEO
        title="Referrals"
        og-title="Referrals &mdash; Silofit"
        og-description=""
      />
      {/* <Helmet>

    </Helmet> */}
      <Section />
      <ReferralsBlock>
        <Page>
          <IndexBlackSectionTitle>
            You just got $30 FREE credits
          </IndexBlackSectionTitle>
          <p>
            To claim your credits, open the referral link on your mobile device
          </p>
          <p>or</p>
          <p>Send a link to your phone to install the Silofit app</p>
          <ContactFormField>
            <ContactFormInput
              type="text"
              placeholder="Phone Number"
              id="contact_phone"
              value={phone}
              onChange={handleChange}
            />
          </ContactFormField>
          <ContactFormActions>
            <ContactFormSubmitButton
              onClick={handleSubmit}
              status={status}
              disabled={status === 'sending'}
            >
              {buttonLabel}
            </ContactFormSubmitButton>
          </ContactFormActions>
          {formError && (
            <ContactFormSubmitError>{formError}</ContactFormSubmitError>
          )}

          <WhatIsSilofit>
            <IndexBlackSectionTitle>What is Silofit?</IndexBlackSectionTitle>
            Silofit is the first generation of private fitness spaces. Book our
            fitness studios by the hour, and unlock the door with your unique
            pin-code. You can go alone, with friends, or even with clients -
            whatever fits your lifestyle.
          </WhatIsSilofit>
        </Page>
      </ReferralsBlock>
    </Layout>
  );
};

ReferralsPage.defaultProps = {
  path: '/for-trainers/',
};

export default ReferralsPage;
