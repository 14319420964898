import { IndexBlackSectionTitle, flexgutter } from '../common';

import { device } from '../../styles/style-utils';
import styled from '@emotion/styled';

export const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const ContactFormLabel = styled.label`
  font-family: 'ProximaNovaRegular';
  font-size: ${(props: any) => props.theme.textSizes.headerMenu}px;
  line-height: ${(props: any) => props.theme.lineHeights.headerMenu}px;
  margin-top: ${(props: any) => props.theme.lineHeights.headerMenu}px;
  @media ${device.tablet} {
    font-size: ${(props: any) => props.theme.textSizes.mobile.headerMenu}px;
    line-height: ${(props: any) => props.theme.lineHeights.mobile.headerMenu}px;
  }
`;

export const ContactFormInput = styled.input`
  width: 100%;
  padding: ${(props: any) => props.theme.textSizes.headerMenu / 2}px 0;
  border-bottom: solid 1px ${(props) => props.theme.colors.dark4};

  :focus {
    outline: none;
  }

  @media ${device.tablet} {
    padding: ${(props: any) => props.theme.textSizes.mobile.headerMenu / 2}px 0;
  }
`;

export const ContactFormSelect = styled.select`
  width: 100%;
  margin: ${(props: any) => props.theme.textSizes.headerMenu / 2}px 0;

  :focus {
    outline: none;
  }

  @media ${device.tablet} {
    margin: ${(props: any) => props.theme.textSizes.mobile.headerMenu / 2}px 0;
  }
`;

export const ContactFormField = styled.div`
  font-family: 'ProximaNovaRegular';
  font-size: ${(props: any) => props.theme.textSizes.headerMenu}px;
  line-height: ${(props: any) => props.theme.lineHeights.headerMenu}px;

  @media ${device.tablet} {
    width: 100%;
    font-size: ${(props: any) => props.theme.textSizes.mobile.headerMenu}px;
    line-height: ${(props: any) => props.theme.lineHeights.mobile.headerMenu}px;
  }
`;

export const ContactFormTitle = styled(IndexBlackSectionTitle)``;

export const ContactFormDescription = styled.div`
  ${flexgutter}
`;

export const ContactFormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`;

export const ContactFormElement = styled.div`
  width: 45%;
  padding-top: 48px;
  margin: auto auto auto 0;
  @media ${device.tablet} {
    width: 100%;
    padding-top: 12px;
  }
`;

export const ContactFormActions = styled.div`
  width: 100%;
  display: flex;
  padding-top: 64px;
  @media ${device.tablet} {
    padding-top: 32px;
  }
`;

export const ContactFormSubmitButton = styled.button`
  font-family: 'ProximaNovaSemiBold';
  margin: auto;
  width: 288px;
  height: 48px;
  border-radius: 24px;
  background-color: ${(props: any) => {
    switch (props.status) {
      case 'sending':
        return props.theme.colors.dark4;
      case 'success':
        return props.theme.colors.green;
      case 'failed':
        return props.theme.colors.redish;
      default:
        return props.theme.colors.dark5;
    }
  }};
  color: ${(props: any) => props.theme.colors.lightsecondary};
  @media ${device.tablet} {
    width: 138px;
  }
`;

export const ContactFormSubmitError = styled.div`
  margin: auto;
  margin-top: 1em;
  font-style: italic;
  color: ${(props: any) => props.theme.colors.redish};
  font-size: ${(props: any) => props.theme.textSizes.headerMenu}px;
  line-height: ${(props: any) => props.theme.lineHeights.headerMenu}px;
  @media ${device.tablet} {
    font-size: ${(props: any) => props.theme.textSizes.mobile.headerMenu}px;
    line-height: ${(props: any) => props.theme.lineHeights.mobile.headerMenu}px;
  }
`;
